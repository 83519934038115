<template>
  <div>
    <v-app-bar app color="primary" dark absolute>
      <v-app-bar-nav-icon class="d-flex d-md-none" @click="drawer = true" />

      <v-img
        src="/media/logos/logo_tktVivax_bw_transparent.png"
        class="mr-4"
        max-height="50"
        max-width="200"
        contain
      ></v-img>

      <div class="d-none d-md-flex">
        <v-btn
          v-for="entry in mainMenu"
          :key="entry.key"
          :to="entry.route"
          text
          plain
        >
          <v-badge
            color="accent"
            :content="entry.meta.badgeContent"
            :value="entry.meta.badgeContent"
            offset-y="10px"
          >
            <v-icon class="mr-3">{{ entry.icon }}</v-icon>
            {{ entry.title }}
          </v-badge>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn class="d-none d-md-flex" text @click="logout">
        <v-icon class="mr-2">mdi-logout-variant</v-icon>
        Abmelden
      </v-btn>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <dialog-change-password
              @password-changed="passwordChanged"
            ></dialog-change-password>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="primary--text text--primary-4">
          <v-list-item
            v-for="entry in mainMenu"
            :key="entry.key"
            :to="entry.route"
          >
            <v-list-item-icon>
              <v-icon>{{ entry.icon }}</v-icon>
            </v-list-item-icon>
            <v-badge
              color="accent"
              :content="entry.meta.badgeContent"
              :value="entry.meta.badgeContent"
              offset-y="10px"
            >
              <v-list-item-title>{{ entry.title }}</v-list-item-title>
            </v-badge>
          </v-list-item>

          <v-divider class="my-3" />

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <dialog-change-password></dialog-change-password>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/auth.module";
import DialogChangePassword from "@/components/dialogs/DialogChangePassword";

export default {
  name: "AppTopMenu",
  components: { DialogChangePassword },
  data() {
    return {
      drawer: false,
      menu: false,
    };
  },
  computed: {
    title() {
      return process.env.VUE_APP_TITLE;
    },
    mainMenu() {
      return this.primaryNavigation;
    },
    color() {
      const rgb = hexToRgb(this.$vuetify.theme.themes.light.primary);
      return rgb.r + ", " + rgb.g + ", " + rgb.b;
    },
    gradient() {
      return (
        "to bottom, rgba(" + this.color + ",.7), rgba(" + this.color + ",0.9)"
      );
    },
    ...mapGetters(["isLogged", "primaryNavigation"]),
  },
  methods: {
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }))
        .catch(() => {});
    },
    passwordChanged() {
      this.$store.dispatch("addAlert", {
        type: "success",
        message: "Passwort erfolgreich geändert!",
      });
      this.menu = false;
    },
  },
};

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
</script>

<style scoped></style>
