import JwtService from "../services/jwt.service";

const state = {
  tokenTimedOut: false,
};

const mutations = {
  SET_TOKEN_TIMED_OUT: (state, tokenTimedOut) => {
    state.tokenTimedOut = tokenTimedOut;
  },
};

const actions = {
  setTokenTimedOut: ({ commit }, tokenTimedOut) => {
    commit("SET_TOKEN_TIMED_OUT", tokenTimedOut);

    if (tokenTimedOut) {
      JwtService.destroyToken();
    }
  },
};

const getters = {
  tokenTimedOut: (state) => state.tokenTimedOut,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
