<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-lock-outline</v-icon>
          Passwort ändern
        </v-btn>
      </template>

      <v-card>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-card-text>
            <v-row no-gutters class="mb-5">
              <v-col>
                <span class="display-1">Passwort ändern</span>
              </v-col>
            </v-row>
            <form-change-password v-model="formData" />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              large
              :disabled="invalid"
              :loading="loading"
              @click="changePassword"
            >
              <v-icon class="mr-2">mdi-login-variant</v-icon>
              Passwort ändern
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormChangePassword from "@/components/forms/FormChangePassword";

export default {
  name: "DialogChangePassword",
  components: { FormChangePassword },
  data() {
    return {
      loading: false,
      formData: {
        token: this.token,
      },
      dialog: false,
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      this.$store
        .dispatch("changePassword", this.formData)
        .then(() => {
          this.loading = false;
          this.$emit("password-changed");
        })
        .catch(({ response }) => {
          switch (response.status) {
            case 429:
              this.$refs.form.setErrors({
                passwordOld:
                  "Zu viele Anfragen. Bitte warten Sie einen Augenblick.",
              });
              break;
            case 422:
              this.$refs.form.setErrors({
                passwordOld: response.data.message,
              });
              break;
            default:
              this.$refs.form.setErrors({
                passwordOld: response.data.message,
              });
              break;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
