import { openDB, deleteDB } from "idb";

const dbPromise = async function () {
  if (!("indexedDB" in window)) {
    // console.error("Browser does not support IndexedDB");
    throw new Error("Browser does not support IndexedDB");
  } // if installed from npm use 'openDB'

  return await openDB("tktVivaxDB", 1, {
    // eslint-disable-next-line no-unused-vars
    upgrade(db, oldVersion, newVersion, transaction) {
      if (!db.objectStoreNames.contains("addresses")) {
        db.createObjectStore("addresses");
      }
    },
  });
};

const IndexedDB = {
  checkStorage: async () => {
    try {
      const db = await dbPromise();
      return db.objectStoreNames;
    } catch (error) {
      return error;
    }
  },
  deleteDatabase: async () => {
    try {
      await deleteDB("tktVivaxDB");
    } catch (error) {
      return error;
    }
  },
  saveToStorage: async (storeName, field) => {
    try {
      const db = await dbPromise();
      const tx = db.transaction([storeName], "readwrite");
      const store = tx.objectStore(storeName);
      await store.put(field.value, field.key);
      return tx.complete;
    } catch (error) {
      return error;
    }
  },
  getStorage: async (storeName) => {
    try {
      const db = await dbPromise();
      const tx = db.transaction([storeName], "readonly");
      const store = tx.objectStore(storeName);
      return store.getAll();
    } catch (error) {
      return error;
    }
  },
  getElement: async (storeName, index) => {
    try {
      const db = await dbPromise();
      const tx = db.transaction([storeName], "readonly");
      const store = tx.objectStore(storeName);
      return store.get(index);
    } catch (error) {
      return error;
    }
  },
};

export default IndexedDB;
