import IndexedDB from "../services/indexeddb.service";
import ApiService from "../services/api.service";
import Vue from "vue";
import store from "./index";

const state = {
  visibleAddresses: [],
  loadedAddressesCount: 0,
  maxAddressesCount: 0,
  latestAddressTableVersion: "1.1",
  lastUpdateTimeStamp: localStorage.getItem("lastAddressUpdateTimeStamp") || "",
  addressTableVersion: localStorage.getItem("addressTableVersion") || "",
};

const mutations = {
  increaseLoadedAddressesCount(state) {
    state.loadedAddressesCount++;
  },

  setVisibleAddresses(state, addresses) {
    state.visibleAddresses = addresses;
  },

  addVisibleAddress(state, address) {
    state.visibleAddresses.push(address);
  },

  updateVisibleAddress(state, address) {
    let index = state.visibleAddresses.findIndex(
      (obj) => obj.address.id === address.address.id
    );
    state.visibleAddresses[index] = address;
  },

  clearVisibleAddresses(state) {
    state.visibleAddresses = [];
  },

  setLastUpdateTimeStamp() {
    let time = new Date();

    let timeStamp = // Y-m-d H:i:s
      time.getFullYear() +
      "-" +
      (time.getMonth() + 1 < 10 ? "0" : "") +
      (time.getMonth() + 1) +
      "-" +
      (time.getDate() < 10 ? "0" : "") +
      time.getDate() +
      " " +
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes() +
      ":" +
      (time.getSeconds() < 10 ? "0" : "") +
      time.getSeconds();

    localStorage.setItem("lastAddressUpdateTimeStamp", timeStamp);
  },

  setAddressTableToCurrentVersion(state) {
    localStorage.setItem(
      "addressTableVersion",
      state.latestAddressTableVersion
    );
  },
};

const actions = {
  async checkAddressStorage() {
    try {
      let data = await IndexedDB.getStorage("addresses");
      if (data === null) data = [];
    } catch (e) {
      // The value in storage was invalid or corrupt so just set it to blank
      // commit("setState", []);
    }

    return "checked storage";
  },

  async deleteAddressStorage() {
    try {
      await IndexedDB.deleteDatabase();
    } catch (e) {
      // The value in storage was invalid or corrupt so just set it to blank
      // commit("setState", []);
    }

    return "deleted storage";
  },

  async getAddresses(context, options) {
    let addresses;
    const params = options;

    await Vue.axios
      .get("sales-control/search", { params })
      .then(({ data }) => {
        addresses = data;
      })
      .catch((e) => {
        if (e.response.status === 403) {
          store.dispatch("setTokenTimedOut", true);
        }

        throw new Error("failed fetching addresses");
      });

    return addresses;
  },

  searchAddresses: async ({ dispatch }, search) => {
    return (await dispatch("getAddresses", { q: search })).data;
  },

  async updateAddresses({ commit, state }) {
    // const isOldVersion =
    //   state.addressTableVersion !== state.latestAddressTableVersion;

    let addresses = [];

    // const date = isOldVersion ? "" : state.lastUpdateTimeStamp;

    //, { date}

    await ApiService.get("addresses").then((response) => {
      addresses = response.data.items;
      state.maxAddressesCount = response.data.count;
    });

    state.loadedAddressesCount = 0;

    try {
      for (let i = 0; i < addresses.length; i++) {
        let currAddress = addresses[i];

        await IndexedDB.saveToStorage("addresses", {
          key: currAddress.address.id,
          value: currAddress,
        });

        state.loadedAddressesCount++;
      }

      commit("setLastUpdateTimeStamp");
      commit("setAddressTableToCurrentVersion");
    } catch (e) {
      // console.error(e);
    }
  },

  async bulkUpdateAddresses({ commit }, data) {
    let success;
    await ApiService.put("addresses", { addresses: data }).then(
      async (response) => {
        if (response.data.items.length > 0) {
          for (let i = 0; i < response.data.items.length; i++) {
            const updatedAddress = response.data.items[i];
            let currAddress = await IndexedDB.getElement(
              "addresses",
              updatedAddress.address.id
            );

            currAddress.status = updatedAddress.status;
            currAddress.address = updatedAddress.address;

            await IndexedDB.saveToStorage("addresses", {
              key: currAddress.address.id,
              value: currAddress,
            });

            commit("updateVisibleAddress", updatedAddress);
          }

          if (response.data.items.length === 1) {
            commit("createAlert", {
              message: "Adresse gespeichert!",
              type: "success",
            });
          } else if (response.data.items.length > 1) {
            commit("createAlert", {
              message: "Adressen gespeichert!",
              type: "success",
            });
          }

          success = true;
        } else {
          commit("createAlert", {
            message: "Fehler beim Speichern!",
            type: "danger",
          });

          success = false;
        }
      }
    );

    return success;
  },

  setVisibleAddresses({ commit }, addresses) {
    commit("setVisibleAddresses", addresses);
  },

  addVisibleAddress({ commit }, address) {
    commit("addVisibleAddress", address);
  },

  clearVisibleAddresses({ commit }) {
    commit("clearVisibleAddresses");
  },
};

const getters = {
  addresses: () => async () => {
    return await IndexedDB.getStorage("addresses");
  },
  searchAddresses: () => async (search) => {
    let result = [];
    await IndexedDB.getStorage("addresses").then(function (addresses) {
      result = addresses.filter(function (address) {
        if (!address.address.street) {
          return false;
        }
        return (
          address.address.street.toLowerCase().includes(search.toLowerCase()) ||
          address.address.zipCode.toString().includes(search.toLowerCase())
        );
      });
    });

    return result;
  },
  loadedAddressesCount: (state) => state.loadedAddressesCount,
  maxAddressesCount: (state) => state.maxAddressesCount,
  visibleAddresses: (state) => () => state.visibleAddresses,
  lastUpdateTimeStamp: (state) => state.lastUpdateTimeStamp,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
