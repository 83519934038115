const state = {
  circles: [],
};

const mutations = {
  ADD_CIRCLE: (state, circle) => {
    state.circles.push(circle);
  },
  DELETE_CIRCLE: (state, circle) => {
    state.circles.splice(state.circles.indexOf(circle), 1);
  },
};

const actions = {
  addCircle: ({ commit }, circle) => {
    commit("ADD_CIRCLE", circle);
  },
  deleteCircle: ({ commit }, circle) => {
    commit("DELETE_CIRCLE", circle);
  },
};

const getters = {
  circles: (state) => state.circles,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
