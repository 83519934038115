<template>
  <div class="text-left">
    <validation-provider vid="passwordOld" rules="required" v-slot="{ errors }">
      <v-text-field
        type="password"
        label="Altes Passwort*"
        :error-messages="errors"
        v-model="formData.password"
        @change="handleInput"
      ></v-text-field>
    </validation-provider>
    <validation-provider
      vid="password"
      rules="required|min:8"
      v-slot="{ errors }"
    >
      <v-text-field
        type="password"
        label="Neues Passwort*"
        :error-messages="errors"
        v-model="formData.passwordNew"
        @change="handleInput"
      ></v-text-field>
    </validation-provider>
    <validation-provider
      vid="passwordRepeated"
      rules="required|confirmedPassword:password"
      v-slot="{ errors }"
    >
      <v-text-field
        type="password"
        label="Neues Passwort wiederholen*"
        :error-messages="errors"
        v-model="formData.passwordNewRepeated"
        @change="handleInput"
      ></v-text-field>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "FormChangePassword",
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          password: "",
          passwordNew: "",
          passwordNewRepeated: "",
        };
      },
    },
  },
  data() {
    return {
      formData: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.formData);
    },
  },
};
</script>

<style scoped></style>
