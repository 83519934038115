<template>
  <div class="fill-height">
    <canvas class="background"></canvas>
    <v-main>
      <v-container class="pb-10 pt-15">
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped lang="scss">
.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>
