<template>
  <div id="app">
    <v-app>
      <component :is="layout" class="fill-height">
        <router-view />
      </component>
    </v-app>
  </div>
</template>

<script>
const DEFAULT_LAYOUT = "base";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + "-layout";
    },
  },
};
</script>

<style lang="scss"></style>
