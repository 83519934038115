const state = {
  primaryNavigation: [
    // {
    //   key: "dashboard",
    //   title: "Dashboard",
    //   icon: "mdi-view-dashboard-outline",
    //   route: "/dashboard",
    //   component: "Dashboard",
    //   meta: {
    //     layout: "default",
    //     auth: true,
    //     title: "Dashboard",
    //     badgeContent: 0,
    //   },
    // },
    {
      key: "addresses",
      title: "Adressen",
      icon: "mdi-map",
      route: "/addresses",
      component: "Addresses",
      meta: {
        layout: "default",
        auth: true,
        title: "Adressen",
        badgeContent: 0,
      },
    },
  ],
  noMenuRoutes: [],
};

const mutations = {
  SET_PRIMARY_NAVIGATION_BADGE_CONTENT: (state, { key, content }) => {
    state.primaryNavigation.find((entry) => {
      return entry.key === key;
    }).meta.badgeContent = content;
  },
};

const actions = {
  setPrimaryNavigationBadgeContent({ commit }, { key, content }) {
    commit("SET_PRIMARY_NAVIGATION_BADGE_CONTENT", { key, content });
  },
};

const getters = {
  primaryNavigation: (state) => state.primaryNavigation,
  noMenuRoutes: (state) => state.noMenuRoutes,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
