<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "BaseLayout",
};
</script>

<style scoped></style>
