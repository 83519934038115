const state = {
  alerts: [],
};

const mutations = {
  ADD_ALERT(state, alert) {
    state.alerts.push(alert);
  },
  REMOVE_ALERT(state, alert) {
    state.alerts.splice(state.alerts.indexOf(alert), 1);
  },
};

const actions = {
  addAlert({ commit }, alert) {
    commit("ADD_ALERT", alert);

    if (alert.type === "success") {
      setTimeout(() => {
        commit("REMOVE_ALERT", alert);
      }, 5000);
    }
  },

  removeAlert({ commit }, alert) {
    commit("REMOVE_ALERT", alert);
  },
};

const getters = {
  alerts: (state) => state.alerts,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
