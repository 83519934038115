<template>
  <div>
    <app-top-menu />
    <app-alerts
      class="app-notifications pa-10"
      style="z-index: 1"
      v-show="true"
    />

    <v-main>
      <v-container class="py-10" fluid>
        <dialog-login />
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppTopMenu from "../components/app/AppTopMenu";
import DialogLogin from "../components/dialogs/DialogLogin";
import AppAlerts from "@/components/app/AppAlerts";

export default {
  name: "DefaultLayout",
  components: { DialogLogin, AppTopMenu, AppAlerts },
};
</script>

<style scoped>
.app-notifications {
  position: absolute;
  right: 0;
  top: 100px;
  max-width: 600px;
}
</style>
