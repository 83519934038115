import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import application from "./application.module";
import circles from "./circles.module";
import profile from "./profile.module";
import addresses from "./addresses.module";
import products from "./products.module";
import filter from "./filter.module";
import alert from "./alert.module";
import navigation from "./navigation.module";
import polygons from "./polygons.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    application,
    circles,
    profile,
    addresses,
    products,
    filter,
    alert,
    navigation,
    polygons,
  },
});
