import ApiService from "../services/api.service";
import JwtService from "../services/jwt.service";
import Vue from "vue";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const CHECK_GROUPS = "checkGroups";
export const CHANGE_PASSWORD = "changePassword";
export const RESET_PASSWORD = "resetPassword";
export const CHECK_CHANGED_PASSWORD = "checkChangedPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_USER_GROUPS = "setUserGroups";
export const SET_CHANGED_PASSWORD = "setChangedPassword";

const state = {
  errors: null,
  user: {},
  apiToken: "",
  isAuthenticated: !!JwtService.getToken(),
  userGroups: {},
  changedPassword: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  userGroups(state) {
    return state.userGroups;
  },
  changedPassword(state) {
    return state.changedPassword;
  },
};

const actions = {
  async [LOGIN]({ commit, dispatch }, credentials) {
    delete Vue.axios.defaults.headers.common["Authorization"];

    return ApiService.post("login", credentials).then(async ({ data }) => {
      if (data.data.token) {
        commit(SET_TOKEN, data.data.token);
        commit("SET_TOKEN_TIMED_OUT", false);
        await dispatch(CHECK_CHANGED_PASSWORD);
      }

      ApiService.setHeader();
    });
  },
  [LOGOUT]({ commit }) {
    commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          commit(SET_ERROR, response.data.errors);
        });
    } else {
      commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put("password", payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [CHECK_GROUPS](context) {
    return ApiService.get("/sales-control/utils/access-rights").then(
      ({ data }) => {
        context.commit(SET_USER_GROUPS, data);
        return data;
      }
    );
  },
  [RESET_PASSWORD]({ commit }, formData) {
    return ApiService.post("change-password", formData).then(({ data }) => {
      console.log(data.data);
      commit(SET_TOKEN, data.data.token);
      return data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  [CHANGE_PASSWORD]({ commit }, formData) {
    return ApiService.put(
      "/sales-control/utils/change-password",
      formData
    ).then(({ data }) => {
      commit(SET_CHANGED_PASSWORD, true);
      return data;
    });
  },
  [CHECK_CHANGED_PASSWORD]({ commit }) {
    return ApiService.get("/sales-control/utils/changed-password").then(
      ({ data }) => {
        commit(SET_CHANGED_PASSWORD, data.data.changedPassword);
        return data;
      }
    );
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    state.apiToken = user.apikey;
    JwtService.saveToken(user.apikey);
  },
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    state.errors = {};
    state.apiToken = token;
    JwtService.saveToken(token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_USER_GROUPS](state, userGroups) {
    state.userGroups = userGroups;
  },
  [SET_CHANGED_PASSWORD](state, changedPassword) {
    state.changedPassword = changedPassword;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
