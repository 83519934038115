import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import ApiService from "./services/api.service";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import VueWorker from "vue-worker";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  alpha_spaces,
  min,
  digits,
} from "vee-validate/dist/rules";

import BaseLayout from "./layouts/BaseLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout";

Vue.use(Donut);
Vue.config.productionTip = false;

Vue.component("base-layout", BaseLayout);
Vue.component("default-layout", DefaultLayout);
Vue.component("auth-layout", AuthLayout);

extend("email", {
  ...email,
  message: "Bitte geben Sie eine gültige E-Mail an.",
});

extend("required", {
  ...required,
  message: "Bitte füllen Sie dieses Feld aus.",
});

extend("confirmedPassword", {
  ...confirmed,
  message: "Die Wiederholung stimmt nicht mit dem Passwort überein.",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Bitte benutzen Sie nur Buchstaben.",
});

extend("min", {
  ...min,
  message: "Das Feld muss mindestens {length} Zeichen haben.",
});

extend("digits", {
  ...digits,
  message: "Das Feld muss aus {length} Zahlen bestehen.",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(VueWorker);

// API service init
ApiService.init();
ApiService.setHeader();

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  let a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
