<template>
  <div class="text-left">
    <validation-provider
      vid="username"
      rules="required|email"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model="formData.username"
        type="email"
        label="E-Mail"
        name="username"
        outlined
        :error-messages="errors"
        @keydown.enter="emitSubmit"
        @change="handleInput"
      >
        <template #append>
          <v-icon>mdi-at</v-icon>
        </template>
      </v-text-field>
    </validation-provider>
    <validation-provider vid="password" rules="required" v-slot="{ errors }">
      <v-text-field
        v-model="formData.password"
        type="password"
        label="Passwort"
        name="password"
        outlined
        :error-messages="errors"
        @keydown.enter="emitSubmit"
        @change="handleInput"
      >
        <template #append>
          <v-icon>mdi-lock-outline</v-icon>
        </template>
      </v-text-field>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "FormLogin",
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          username: "",
          password: "",
        };
      },
    },
  },
  data() {
    return {
      formData: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.formData);
    },
    emitSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped></style>
