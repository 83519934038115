<template>
  <v-dialog v-model="tokenTimedOut" persistent max-width="450">
    <v-card>
      <v-card-title class="text-h5">
        Bitte melden Sie sich erneut an
      </v-card-title>

      <validation-observer ref="loginForm" v-slot="{ invalid }">
        <v-card-text>
          Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.

          <form-login class="mt-5" v-model="loginData" @submit="login" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text plain :disabled="loading" @click="logout">
            Abmelden
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="invalid"
            @click="login"
          >
            Anmelden
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
y
<script>
import FormLogin from "@/components/forms/FormLogin";
import { mapGetters } from "vuex";
import { LOGIN, LOGOUT } from "@/store/auth.module";

export default {
  name: "DialogLogin",
  components: { FormLogin },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["tokenTimedOut"]),
  },
  methods: {
    async login() {
      this.loading = true;

      this.$store
        .dispatch(LOGIN, this.loginData)
        .then(() => {
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;

          switch (response.status) {
            case 429:
              this.$refs.loginForm.setErrors({
                email: "Zu viele Anfragen. Bitte warten Sie einen Augenblick.",
              });
              break;
            case 422:
              this.$refs.loginForm.setErrors(response.data.errors);
              break;
            default:
              this.$refs.loginForm.setErrors({
                password: ["Die Login Daten sind nicht korrekt."],
              });
              break;
          }
        });
    },

    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }))
        .catch(() => {});
    },
  },
};
</script>

<style scoped></style>
