<template>
  <div>
    <v-alert
      v-for="(alert, index) in alerts"
      :key="index"
      :type="alert.type"
      :dismissible="alert.type !== 'success'"
      class="text--darken-2"
      elevation="5"
      transition="slide-y-transition"
      @input="closeAlert(alert)"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "AppAlerts",
  computed: {
    alerts() {
      return this.$store.getters.alerts;
    },
  },
  methods: {
    closeAlert(alert) {
      this.$store.dispatch("removeAlert", alert);
    },
  },
};
</script>

<style scoped></style>
