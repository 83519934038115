const state = {
  polygons: [],
};

const mutations = {
  ADD_POLYGON: (state, polygon) => {
    state.polygons.push(polygon);
  },
  DELETE_POLYGON: (state, polygon) => {
    state.polygons.splice(state.polygons.indexOf(polygon), 1);
  },
  DELETE_ALL_POLYGONS: (state) => {
    for (let i = 0; i < state.polygons.length; i++) {
      state.polygons[i].polygon.setMap(null);
    }

    state.polygons.splice(0, state.polygons.length);
  },
};

const actions = {
  addPolygon: ({ commit }, polygon) => {
    commit("ADD_POLYGON", polygon);
  },
  deletePolygon: ({ commit }, polygon) => {
    commit("DELETE_POLYGON", polygon);
  },
  deleteAllPolygons: ({ commit }) => {
    commit("DELETE_ALL_POLYGONS");
  },
};

const getters = {
  polygons: (state) => state.polygons,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
