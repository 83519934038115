import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      name: "root",
      redirect: "/application-setup",
      component: () => import("../layouts/BaseLayout"),
      meta: {
        layout: "default",
        auth: true,
      },
      children: [
        {
          path: "/application-setup",
          name: "application-setup",
          component: () => import("../views/ApplicationSetup.vue"),
          meta: {
            layout: "default",
            auth: true,
            title: "DSN - Application Setup",
          },
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("../views/Dashboard.vue"),
          meta: {
            layout: "default",
            auth: true,
            title: "DSN - Dashboard",
          },
        },
        {
          path: "/addresses",
          name: "addresses",
          component: () => import("../views/Addresses.vue"),
          meta: {
            layout: "default",
            auth: true,
            title: "DSN - Adressen",
          },
        },
      ],
    },
    {
      path: "/",
      component: () => import("../views/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("../views/auth/Login"),
          meta: {
            layout: "auth",
            auth: false,
            title: "DSN - Login",
          },
        },
      ],
    },
    // {
    //   name: "request-password-reset",
    //   path: "/request-password-reset",
    //   component: () => import("../views/auth/RequestPasswordReset"),
    //   meta: {
    //     layout: "auth",
    //     auth: false,
    //     title: "Passwort vergessen?",
    //   },
    // },
    // {
    //   name: "reset-password",
    //   path: "/reset-password/:token",
    //   component: () => import("../views/auth/ResetPassword"),
    //   meta: {
    //     layout: "auth",
    //     auth: false,
    //     title: "Passwort zurücksetzen",
    //   },
    //   props: true,
    // },
    {
      name: "change-password",
      path: "/change-password",
      component: () => import("../views/auth/ChangePassword"),
      meta: {
        layout: "auth",
        auth: true,
        title: "Passwort ändern",
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("../views/error/Error404"),
      meta: {
        layout: "default",
        auth: true,
        title: "DSN - 404",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.isAuthenticated;

  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next({ name: "login" });
    return;
  }
  next();
});

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;
router.afterEach((to) => {
  Vue.nextTick(() => {
    if (
      to.meta.title &&
      {}.toString.call(to.meta.title) === "[object Function]"
    ) {
      document.title = to.meta.title(to);
    } else {
      document.title = to.meta.title || DEFAULT_TITLE;
    }
  });
});

export default router;
