import ApiService from "../services/api.service";

const state = {
  status: null,
  filter: {},
};

const mutations = {
  setStatus: (state, status) => {
    state.status = status;
  },

  setFilter: (state, filter) => {
    state.filter = filter;
  },

  setStatusFilter: (state, statusFilter) => {
    state.filter.status = statusFilter;
  },

  setMapToolFilter: (state, addressIds) => {
    state.filter.mapTools = addressIds;
  },
};

const actions = {
  async updateStatus({ commit, state }) {
    let status = state.status;

    await ApiService.get("sales-control/utils/status").then(({ data }) => {
      status = data.data;
    });

    const statusEdited = [];
    status.map((obj) => {
      const newObj = {
        values: [],
      };

      Object.keys(obj).map((key) => {
        switch (key) {
          case "id":
          case "name":
            newObj[key] = obj[key];
            break;

          default:
            newObj.values.push(obj[key]);
            break;
        }
      });

      statusEdited.push(newObj);
    });

    commit("setStatus", statusEdited);
  },

  setStatusFilter({ commit }, statusFilter) {
    commit("setStatusFilter", statusFilter);
  },

  setMapToolFilter({ commit }, addressIds) {
    commit("setMapToolFilter", addressIds);
  },
};

const getters = {
  status: (state) => state.status,

  webFilterArray: (state) => () => {
    const status = state.status;
    const filter = state.filter.status || {};

    let webFilterArray = [];
    let index = 0;

    for (const [key, value] of Object.entries(filter)) {
      let values = [];

      for (let i = 0; i < value.length; i++) {
        values.push(
          status
            .find(({ name }) => name === key)
            .values.find((obj) => obj.key === value[i]).value
        );
      }

      const entry = {
        key: "statusFilter[" + index + "][" + key + "]",
        value: values,
      };

      const entry2 = {
        key: "status[" + index + "]",
        value: key,
      };

      webFilterArray.push(entry);
      webFilterArray.push(entry2);
      index++;
    }

    return webFilterArray;
  },

  filter: (state) => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
